<template>
  <div>
    <div v-if="technician">
      <div class="py-2 flex flex-col">
        <div class="text-lg text-bold font-serif text-center border-b border-secondary-300 pb-2">{{ $t("tech_dashboard.welcome") }} {{ technician.name }}</div>
      </div>

      <div class="flex flex-wrap mt-3">
        <div class="w-full lg:w-1/4 lg:pr-3">
          <router-link tag="div" to="/config/alarm" class="flex flex-col items-center px-4 py-6 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
            <BaseIcon icon="cogs" class="text-6xl" />
            <div class="font-semibold font-serif mt-3">{{ $t("tech_dashboard.config") }}</div>
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 lg:pr-3">
          <router-link tag="div" to="/admin/error-logs" class="flex flex-col items-center px-4 py-6 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
            <BaseIcon icon="exclamation-triangle" class="text-6xl" />
            <div class="font-semibold font-serif mt-3">{{ $t("tech_dashboard.all_errors") }}</div>
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 lg:pr-3">
          <router-link tag="div" to="/alarm-report/search" class="flex flex-col items-center px-4 py-6 mt-2 text-center cursor-pointer hover:bg-primary-100 transition-all duration-300">
            <BaseIcon icon="table" class="text-6xl" />
            <div class="font-semibold font-serif mt-3">{{ $t("tech_dashboard.alarm_reports") }}</div>
          </router-link>
        </div>
        <div class="w-full lg:w-1/4 lg:pr-3">
          <div class="flex flex-col items-center px-4 py-3 mt-2">
            <img class="h-11" :src="require('../../assets/icon/support.png')" />
            <div class="text-sm text-left mt-1">
              {{ $t("tech_dashboard.support_message") }}
              <a href="mailto:support@securdia.se" class="text-primary-400 underline hover:text-primary-600 transition-all duration-100">support@securdia.se</a>
              {{ $t("tech_dashboard.support_message_2") }} <a href="tel:0852027642" class="text-primary-400 underline hover:text-primary-600 transition-all duration-100">08-520 276 42</a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mt-5">
        <div class="w-full lg:w-1/2 mb-3">
          <div class="bg-secondary-200 border border-secondary-300 px-4 py-3">
            <div class="border-b border-secondary-300 mb-3 text-lg font-semibold font-serif">Sålda produkter</div>
            <AlarmsChart v-if="alarmsData != null" :chartData="alarmsData" :labels="chartLabels" :legend="legend" centerText="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AlarmsChart from "@/components/doughnut_chart";

export default {
  name: "DashboardTech",
  title() {
    return `Tekniker | SecurCloud`;
  },
  components: {
    AlarmsChart,
  },
  data() {
    return {
      user: this.$store.state.user,
      tech_id: this.$route.params.id,
      technician: null,
      legend: {
        position: "right",
        align: "middle",
        labels: {
          generateLabels(chart) {
            let data = chart.data;
            if (data.labels.length && data.datasets.length) {
              return data.labels.map(function(label, i) {
                let meta = chart.getDatasetMeta(0);
                let datasets = data.datasets[0];
                let arc = meta.data[i];
                let value = chart.config.data.datasets[arc._datasetIndex].data[arc._index];

                return {
                  text: `${label} - ${value}`,
                  fillStyle: datasets.backgroundColor[i],
                  lineWidth: 0,
                  hidden: isNaN(datasets.data[i]) || meta.data[i].hidden,
                  index: i,
                };
              });
            }
          },
        },
      },
      alarmsData: null,
    };
  },
  methods: {
    async getTechnicianDashboard() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/dashboard/technician/${this.tech_id}`);
        this.technician = response.data.technician;

        this.alarmsData = [
          {
            data: [response.data.total_alarm, response.data.total_inactive, response.data.total_active, response.data.no_report, response.data.other_products],
            backgroundColor: ["#00C16E", "#ff6565", "#a2ffd7", "#E8E8E8", "#ffffaa"],
          },
        ];

        this.setPageTitle(`${this.technician.name} - Dashboard`, "tech_dashboard");
      } catch (error) {
        this.handleError(error);
      }
    },
  },
  computed: {
    chartLabels() {
      return [this.$t("tech_dashboard.number_of_alarms"), this.$t("tech_dashboard.deactivated"), this.$t("tech_dashboard.activated"), this.$t("tech_dashboard.no_report"), this.$t("tech_dashboard.other_products")];
    },
  },
  created() {
    this.getTechnicianDashboard();
  },
};
</script>
